import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PageContext from '../../types/PageContext'

const RouteNotFound: React.FunctionComponent<RouteNotFoundProps> = (props: RouteNotFoundProps) => (
    <Container>
        <Row>
            <Col sm={12}>
                <h1>404 - Page not found</h1>
            </Col>
        </Row>
    </Container>
)

export default RouteNotFound

interface RouteNotFoundProps {
    pageContext: PageContext<any>
    path: any
}
