import * as React from 'react'
import { handleLogin } from '../../services/auth.js'
import GlobalState from '../../types/GlobalState.js'
import withState from '../hoc/withState'
import LoginForm from './form/LoginForm'

class LoginByForm extends React.Component<LoginByFormProps, LoginByFormState> {
    handleLoginFromForm = (result: any) => {
        if (result) {
            const { token, fullName } = result

            const loginSuccess = handleLogin(token, fullName)

            if (loginSuccess) {
                const action = {
                    type: 'login',
                    payload: {
                        username: fullName,
                    },
                }

                this.props.dispatch(action)

                if (this.props.onLogin) {
                    this.props.onLogin(result)
                }
            }
        }
    }

    render() {
        const { download, loginDisabled, showError, acceptedTerms } = this.props

        return (
            <LoginForm
                onSubmit={this.handleLoginFromForm}
                download={download}
                loginDisabled={loginDisabled}
                acceptedTerms={acceptedTerms}
                showError={showError}
            />
        )
    }
}

export default withState(LoginByForm)

interface LoginByFormProps extends GlobalState {
    onLogin?: any
    download?: boolean
    loginDisabled?: boolean
    acceptedTerms?: boolean
    showError?: any
}

interface LoginByFormState {}
