import { navigate } from 'gatsby'
import * as React from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import PageContext from '../../types/PageContext'
import Translation from '../../types/Translation'
import LoginByForm from '../content/LoginByForm'
import withLocation from '../hoc/withLocation'
import withTranslations from '../hoc/withTranslations'
import * as styles from './Portal.module.scss'

const Portal: React.FunctionComponent<PortalProps> = (props: PortalProps) => {
    const { t, location, pageContext } = props
    const { mypdfUrls } = pageContext

    // TODO: resolve eslintreact/jsx-no-bind: "JSX props should not use arrow functions"
    const handleLogin = () => {
        const mypdfEntry = mypdfUrls.mypdfEntry
        const mypdfHome = mypdfUrls.mypdfHome

        const { pathname, search } = location
        let forwardTo = pathname + search

        if (pathname === mypdfEntry) {
            forwardTo = mypdfHome
        }
        navigate(forwardTo)
    }

    let hasLoggedOut = false

    if (location.state && location.state.loggedOut) {
        hasLoggedOut = true
    }

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    {hasLoggedOut && <Alert variant={'success'}>{t('template', 'general.logout.message.success')}</Alert>}
                    <h1>{t('template', 'mypdftools.portal.title')}</h1>
                </Col>
            </Row>
            <Row className={styles.loginBg}>
                <Col sm={{ span: 4, offset: 4 }}>
                    <LoginByForm onLogin={handleLogin} />
                </Col>
            </Row>
            <Row>
                <Col sm={12} dangerouslySetInnerHTML={{ __html: t('template', 'mypdftools.portal.content.portal') }} />
            </Row>
            <Row>
                <Col sm={12}>
                    <h3 className={'underline'}>{t('template', 'mypdftools.portal.title.registered')}</h3>
                </Col>
            </Row>
            <Row>
                <Col sm={6} dangerouslySetInnerHTML={{ __html: t('template', 'mypdftools.portal.list.free') }} />
                <Col sm={6} dangerouslySetInnerHTML={{ __html: t('template', 'mypdftools.portal.list.newsletter') }} />
            </Row>
        </Container>
    )
}

export default withTranslations(withLocation(Portal))

interface PortalProps extends Translation {
    location: any
    pageContext: PageContext<any>
}
