import * as React from 'react'
import { IMyPDFTemplateData } from '../../types/MyPDF'
import { Pagecontent } from '../../types/PageContent'
import PageContext from '../../types/PageContext'
import GridLayoutRenderer from '../content/GridLayoutRenderer'

const MyPDFToolsGeneric: React.FunctionComponent<MyPDFToolsGenericProps> = (props: MyPDFToolsGenericProps) => {
    const { pageContext, grids } = props

    const gridElements = grids?.map((grid) => (
        <GridLayoutRenderer key={`grid_${grid.directusId}`} pageContext={pageContext} rootItem={grid} />
    ))

    return <>{gridElements}</>
}

export default MyPDFToolsGeneric

interface MyPDFToolsGenericProps {
    pageContext: PageContext<IMyPDFTemplateData>
    grids: Pagecontent[]
}
