
import { navigate, useLocation, useMatch } from '@reach/router'
import React, { useEffect } from 'react'
import { Alert, Form } from 'react-bootstrap'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import ColoredBox from '../../content/ColoredBox'
import withLocation from '../../hoc/withLocation'
import withTranslations from '../../hoc/withTranslations'
import usePasswordReset, { ErrorStates } from './useResetPassword'

interface VerifyPasswordChangeProps extends Translation {
    token: string;
    location: any
    pageContext: PageContext<any>
}

const VerifyPasswordChangeContainer = (props: VerifyPasswordChangeProps) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '500px' }}>
            <VerifyPasswordChange {...props} />
        </div>
    )
}


const VerifyPasswordChange = (props: VerifyPasswordChangeProps) => {
    const { t } = props


    const { error, setError, success, loading, email, password, confirmPassword, setEmail, setPassword, setConfirmPassword, handleSubmit } = usePasswordReset(props.token);

    useEffect(() => {
        // TODO: call verify token and set state if token is wrong
        // NOTE: can't be done now on page load because API asks for email
    }, [])


    return (
        <div style={{ minWidth: 400 }}>
            <ColoredBox>
                <div style={{ padding: '20px' }}>
                    {!success && <Form
                        onSubmit={handleSubmit}
                    >
                        <Form.Group controlId={'form.email'}>
                            <Form.Label>
                                {t('form', 'general.field.email.label')} <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                required
                                name="email"
                                isInvalid={error === ErrorStates.MissingEmail}
                                value={email}
                                onChange={(e: any) => {
                                    setError(ErrorStates.NoError)
                                    setEmail(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid" style={error === ErrorStates.MissingEmail ? { display: 'unset' } : {}}>
                                {t('form', 'form.validate.email.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId={'password'} >
                            <Form.Label>
                                {t('form', 'general.field.newPassword.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                autoComplete="new-password"
                                type="password"
                                name={'password'}
                                value={password}
                                isInvalid={error === ErrorStates.MissingPassword
                                    || error === ErrorStates.PasswordsDoNotMatch
                                    || error === ErrorStates.PasswordTooShort}
                                onChange={(e: any) => {
                                    setError(ErrorStates.NoError)
                                    setPassword(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid" style={error === ErrorStates.MissingPassword ? { display: 'unset' } : { display: 'none' }}>
                                {t('form', 'general.validate.password.invalid')}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" style={error === ErrorStates.PasswordTooShort ? { display: 'unset' } : { display: 'none' }}>
                                {t('form', 'general.validate.password.invalid.tooShort')}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId={'confirmPassword'}>
                            <Form.Label>
                                {t('form', 'general.field.confirm.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                autoComplete="new-password"
                                name={'confirmPassword'}
                                type={'password'}
                                value={confirmPassword}
                                isInvalid={error === ErrorStates.PasswordsDoNotMatch || error === ErrorStates.MissingConfirmPassword}
                                onChange={(e: any) => {
                                    setError(ErrorStates.NoError)
                                    setConfirmPassword(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid" style={error === ErrorStates.PasswordsDoNotMatch ? { display: 'unset' } : {}}>
                                {t('form', 'general.validate.password.invalid.passwordsDoNotMatch')}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <input
                                disabled={loading}
                                type={'submit'}
                                className={'btn btn-primary'}
                                value={t('form', 'general.field.changePasswordSubmit.label')}
                            />

                        </Form.Group>
                        {error === ErrorStates.GenericError && <Alert variant={'danger'}>  {t('form', 'general.updatePassword.genericRequestError')}</Alert>}
                    </Form>}
                    {success && (
                        <>
                            <RedirectToStartPage />
                        <div>
                            {t('form', 'general.updatePassword.successMessage')}
                        </div>
                        </>
                    )}
                </div>
            </ColoredBox>

        </div >
    )
}

export default withTranslations(withLocation(VerifyPasswordChangeContainer))

const RedirectToStartPage = () => {
    const match = useMatch('/:lang/mypdftools/*');
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            if (match) {
                navigate(`/${match.lang}/mypdftools/`)
            }
        }, 3000)
    }, [])

    return null
}