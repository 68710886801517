import { Router } from '@reach/router'
import * as React from 'react'
import MyPDFToolsRoute from '../components/content/MyPDFToolsRoute'
import Portal from '../components/mypdftools/Portal'
import RouteNotFound from '../components/mypdftools/RouteNotFound'
import VerifyPasswordChange from '../components/mypdftools/verifypasswordchange/VerifyPasswordChange'
import MainLayout from '../layouts/MainLayout'
import { IMyPDFTemplateData } from '../types/MyPDF'
import PageContext from '../types/PageContext'

const MyPDFToolsPageTemplate: React.FunctionComponent<MyPDFToolsPageTemplateProps> = (props: MyPDFToolsPageTemplateProps) => {
    const { pageContext } = props
    const { templateData, mypdfUrls } = pageContext

    const routes = templateData?.routes.map((route) => (
        <MyPDFToolsRoute key={route.path} path={route.path} route={route} pageContext={pageContext} />
    ))

    routes?.unshift(<Portal path={mypdfUrls.mypdfEntry} pageContext={pageContext} />)
    routes?.unshift(<VerifyPasswordChange path="/:language/mypdftools/change-password/:token/*" pageContext={pageContext} />)
    routes?.push(<RouteNotFound path="*" pageContext={pageContext} />)

    return (
        <MainLayout pageContext={pageContext}>
            <Router>{routes}</Router>
        </MainLayout>
    )
}

export default MyPDFToolsPageTemplate

interface MyPDFToolsPageTemplateProps {
    pageContext: PageContext<IMyPDFTemplateData>
}


