import { useCallback, useState } from 'react';
import axiosUtil from '../../../utils/axios';

export enum ErrorStates {
    NoError,
    PasswordTooShort,
    MissingEmail,
    MissingPassword,
    MissingConfirmPassword,
    PasswordsDoNotMatch,
    TokenInvalid,
    GenericError,
}

const verifyToken = async (token: string, email: string) => {
    return await axiosUtil.get('/api/v1.0/Users/ChangePasswordVerify', {
        data: {
            "token": token,
            "email": email
        },
    })
}

const updatePassword = async (password: string, token: string, email: string) => {
    return await axiosUtil.post('/api/v1.0/Users/ChangePassword', {
        "newPassword": password,
        "token": token,
        "email": email
    })
}


const usePasswordReset = ( token: string) => {
    const [error, setError] = useState(ErrorStates.NoError);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!email) {
            setError(ErrorStates.MissingEmail)
            return;
        }
        if (!password) {
            setError(ErrorStates.MissingPassword)
            return;
        }
        if (password.length < 8) {
            setError(ErrorStates.PasswordTooShort)
            return;
        }
        if (!confirmPassword) {
            setError(ErrorStates.MissingConfirmPassword)
            return;
        }
        if (password !== confirmPassword) {
            setError(ErrorStates.PasswordsDoNotMatch);
            return;
        }

        try {
            setLoading(true);
            await updatePassword(password, token, email);
            setSuccess(true);
            setError(ErrorStates.NoError)
        } catch (e) {
            setError(ErrorStates.GenericError)
        } finally {
            setLoading(false);
        }
    }

    const verifyTokenThenHandleSubmit = useCallback(async (event: any) => {
        event.preventDefault();
        // TODO: add this once the verify API is available
        // if (email) {
        //     try {
        //         await verifyToken(token, email);
        //     }
        //     catch (e) {
        //         setError(ErrorStates.TokenInvalid)
        //     }
        // }
        handleSubmit(event);
    },
        [handleSubmit, email])

    return { error, setError, success, loading, email, password, confirmPassword, setEmail, setPassword, setConfirmPassword, handleSubmit: verifyTokenThenHandleSubmit };
}

export default usePasswordReset;