import React from 'react'
import { Redirect } from '@reach/router'
import { isLoggedIn } from '../../services/auth.js'
import { renderMetaTags } from '../../layouts/MetaHead'
import MyPDFToolsGeneric from '../../components/mypdftools/MyPDFToolsGeneric'
import PageContext from '../../types/PageContext'
import Portal from '../mypdftools/Portal'
import { IMyPDFTemplateData, IRoute } from '../../types/MyPDF.js'

const MyPDFToolsRoute: React.FunctionComponent<MyPDFToolsRouteProps> = (props: MyPDFToolsRouteProps) => {
    const { route, pageContext } = props
    const loggedIn = isLoggedIn()

    // require login for private urls
    if (route.visibility === 'loggedInOnly' && !isLoggedIn()) {
        return <Portal pageContext={pageContext} />
    }

    // hide anonymousOnly pages for authenticated users and redirect to myPdftools home
    if (route.visibility === 'anonymousOnly' && loggedIn) {
        return <Redirect to={pageContext.mypdfUrls.mypdfHome} noThrow />
    }

    return (
        <>
            {renderMetaTags(route.meta, pageContext.language)}
            <MyPDFToolsGeneric pageContext={pageContext} grids={route.grids} />
        </>
    )
}

interface MyPDFToolsRouteProps {
    pageContext: PageContext<IMyPDFTemplateData>
    route: IRoute
    path: string // required for React router
    exact?: boolean // for React router
}

export default MyPDFToolsRoute
